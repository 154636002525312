import React, { Component } from 'react';
import { intercomMessenger, MaintenanceMode, Exception } from 'componentlibrary';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { renderRoutes } from 'react-router-config';
import { withTranslation } from 'react-i18next';
import { Button, Spin, Icon } from 'antd';
import Config from '../../Config';
import ResponsiveLayout from '../../Containers/ResponsiveLayout';

import AuthModal from '../../Containers/AuthModal';
import { routes } from '../../routes';
import { loader } from '../../sass/modules/loader.module.scss';
import styles from './style.module.scss';
import { getCookieLangFromID, getCookieKeyForLocale } from '../../utils/CookieData';

export class App extends Component {
  constructor(props) {
    super(props);

    this.hasInitializedIntercom = false;
    this.state = {
      showError: false,
      authAttempted: false,
      errorMessage: '',
      loadingApp: true
    };
  }

  // Make componentDidMount async so we can await getAppConfig
  async componentDidMount() {
    const { wsConnect, configServiceFetchRequest } = this.props;
    this.setState({ loadingApp: true });
    // Wait for the app config to load before proceeding
    await this.getAppConfig();

    const { appConfig } = this.props;

    if (appConfig && appConfig.maintenanceMode === false) {
      await this.handleAuth();

      await this.validateCsrfToken();
    }

    configServiceFetchRequest(Config.configurationService.URL);
    wsConnect(Config.configurationService.WEBSOCKET);
    this.setState({ loadingApp: false });
  }

  componentDidUpdate(prevProps) {
    const { auth, appConfig, i18n } = this.props;
    const { showError, authAttempted } = this.state;
    const { idToken } = auth;

    // Only call handleAuth() if the application loads with maintenanceMode
    // turned off or if it was previously on and now it's been turned off.
    if ((prevProps.appConfig
      && prevProps.appConfig.maintenanceMode === true
      && appConfig.maintenanceMode === false)
      || (prevProps.appConfig === null && appConfig && appConfig.maintenanceMode === false)) {
      this.handleAuth();
    }

    if (!authAttempted
      && prevProps.appConfig === null
      && appConfig
      && !appConfig.maintenanceMode
      && idToken === null) {
      this.setState({ authAttempted: true }, () => {
        this.handleAuth();
      });
    }

    // Handle language change based on cookie
    const username = idToken && idToken['cognito:username'] ? idToken['cognito:username'] : '';
    const cookieLanguage = getCookieLangFromID(getCookieKeyForLocale(username));

    if (
      prevProps.i18n
      && cookieLanguage
      && prevProps.i18n.language !== cookieLanguage
    ) {
      const currentLocale = cookieLanguage;
      i18n.changeLanguage(currentLocale);
    }

    if (prevProps
        && prevProps.i18n
        && cookieLanguage
        && prevProps.i18n.language !== cookieLanguage) {
      const currentLocale = cookieLanguage;
      i18n.changeLanguage(currentLocale);
    }

    if (auth.error && !showError) {
      this.handleAuthError(auth.error);
    }
  }

  componentWillUnmount() {
    clearInterval(this.appConfigInterval);
    const { wsDisconnect } = this.props;
    wsDisconnect();
  }

  async handleAuth() {
    const { authInit, appConfig } = this.props;
    const { appClientId, userPoolId, reactAppCookieDomain } = appConfig;
    const forwoodIdUrl = `${Config.forwoodId.URL}?redirect_uri=${Config.reactApp.HOSTNAME}`;
    const payload = {
      appClientId,
      userPoolId,
      cookieStorage: reactAppCookieDomain,
      forwoodIdUrl,
      teamsAccessRole: Config.reactApp.TEAMS_ACCESS_ROLE,
      teamTargetsRole: Config.reactApp.TEAM_TARGETS_ACCESS_ROLE
    };
    authInit(payload);
  }

  handleAuthError() {
    this.setState({
      showError: true
    });
  }

  get loaderMessage() {
    const { auth } = this.props;

    if (auth.authorizing) {
      return 'redirecting';
    }

    if (this.getSearchParam('code') !== null) {
      return 'authorizing';
    }

    return null;
  }

  get intercomPayload() {
    const { auth = {} } = this.props;

    if (!auth.idToken) {
      return {};
    }

    return {
      user_id: auth.idToken['custom:forwood_uuid'],
      user_hash: auth.idToken.hmac,
    };
  }

  async getAppConfig() {
    const { getAppConfig } = this.props;
    await getAppConfig();
    this.appConfigInterval = setInterval(getAppConfig.bind(this), 60 * 1000);
  }

  getSearchParam(param) {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    if (params.has(param)) {
      return params.get(param);
    }

    return null;
  }

  async validateCsrfToken() {
    const { auth } = this.props;
    if (!auth || !auth.idToken) {
      return;
    }

    const userJwt = auth.idTokenJwtString;

    try {
      const validateUrl = `${Config.forwoodId.URL}/csrf-token/validate`;

      const response = await fetch(validateUrl, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${userJwt}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('CSRF token validation failed:', errorData.error);
        this.setState({ showError: true, errorMessage: errorData.error });
        return;
      }
    } catch (error) {
      console.error('CSRF token validation failed:', error);
      this.setState({ showError: true });
    }
  }

  redirect() {
    const { history } = this.props;
    const pathname = sessionStorage.getItem('lastPath') || '/';
    return history.push(pathname);
  }

  render() {
    const { showError, errorMessage, loadingApp } = this.state;
    const {
      t, appConfig, showAuthConfirm, auth = {}, authorize, logout
    } = this.props;
    const { idToken, error, accessToken } = auth;
    if (!appConfig) {
      return null;
    }

    if (loadingApp) {
      return null;
    }

    if (showError) {
      return (
        <Exception status="401" message={errorMessage}>
          <Button onClick={logout}>
            {t('errors:tryAgain')}
          </Button>
        </Exception>
      );
    }

    if (this.loaderMessage) {
      return (
        <Spin
          className={loader}
          size="large"
          tip={t(this.loaderMessage)}
        >
          <div />
        </Spin>
      );
    }

    if (error && showError) {
      return (
        <Exception
          status={error.status}
          message={t(`errors:${error.status.toString()}`)}
        >
          <Button onClick={authorize}>
            {t('errors:tryAgain')}
          </Button>
        </Exception>
      );
    }

    if (appConfig && appConfig.maintenanceMode) {
      return (
        <MaintenanceMode
          title={t('maintenanceMode:systemDown')}
          description={t('maintenanceMode:pleaseLogout')}
        />
      );
    }

    if (!accessToken) {
      return (
        <AuthModal />
      );
    }

    const {
      permission: { teams, teamTargets }, i18n,
    } = this.props;

    const menuItem = [];
    const currentURL = window.origin;

    const username = idToken && idToken['cognito:username'] ? idToken['cognito:username'] : '';
    const cookieLanguage = getCookieLangFromID(getCookieKeyForLocale(username));

    if (teams && teams.access) {
      menuItem.push(
        {
          container: 'TeamsMicroFrontend',
          icon: <Icon type="team" className={styles.icon} />,
          title: t('teams'),
          path: '/teams'
        }
      );
    }

    if (teamTargets && teamTargets.access) {
      menuItem.push(
        {
          container: 'TargetsMicroFrontend',
          icon: <Icon type="safety" className={styles.icon} />,
          title: t('targets'),
          path: '/targets'
        }
      );
    }

    if (!showAuthConfirm && appConfig.intercomEnabled && !this.hasInitializedIntercom) {
      intercomMessenger(
        Config.reactApp.HOSTNAME,
        cookieLanguage,
        this.intercomPayload,
        Config.ENVIRONMENT_NAME
      );
      this.hasInitializedIntercom = true;
    }

    return (
      <>
        <ResponsiveLayout
          menuProps={menuItem}
          i18n={i18n}
          enabledSupportButtons={appConfig.enabledSupportButtons}
          currentUrl={currentURL}
        >
          {renderRoutes(routes)}
          <AuthModal />
        </ResponsiveLayout>

      </>
    );
  }
}

App.defaultProps = {
  auth: {},
  appConfig: null,
  showAuthConfirm: false,
  configServiceFetchRequest: null
};

App.propTypes = {
  auth: PropTypes.object,
  appConfig: PropTypes.object,
  i18n: PropTypes.object.isRequired,
  getAppConfig: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  permission: PropTypes.object.isRequired,
  showAuthConfirm: PropTypes.bool,
  wsConnect: PropTypes.func.isRequired,
  configServiceFetchRequest: PropTypes.func,
  wsDisconnect: PropTypes.func.isRequired,
  authInit: PropTypes.func.isRequired,
  authorize: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired
};

export default withTranslation(['common', 'errors', 'maintenanceMode'])(withRouter(App));
